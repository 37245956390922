import React, { } from 'react';
import logo from './images/Logo-Zeposia.png'
import './App.css';
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect
} from 'react-router-dom'
import Footer from './components/Footer';
import DeviceOrientation, { Orientation } from 'react-screen-orientation'
import { ThemeProvider } from '@fluentui/react'
import { themeBMS } from './themes'

//Pages
const HomeContainer = React.lazy(() => import('./components/HomeContainer'));
const CostesFarmacosContainer = React.lazy(() => import('./components/CostesFarmacosContainer'));
const CosteFarmacologicoAnualContainer = React.lazy(() => import('./components/CosteFarmAnualContainer'));
const CosteCohorteAnualContainer = React.lazy(() => import('./components/CosteCohorteAnualContainer'));
const ParametrosContainer = React.lazy(() => import('./components/ParametrosContainer'));
const ParametrosEspecificosContainer = React.lazy(() => import('./components/ParametrosEspecificosContainer'));
const ConfiguracionContainer = React.lazy(() => import('./components/ConfiguracionContainer'));
const ResultadosContainer = React.lazy(() => import('./components/ResultadosContainer'));
const AutologinComponent = React.lazy(() => import('./components/AutologinComponent'))

function App() {
  return (
    <>
      <DeviceOrientation lockOrientation={'landscape'}>
        <Orientation orientation='landscape' alwaysRender={false}>
          <div class="ms-Grid" dir="ltr" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <BrowserRouter>
              <React.Suspense fallback={'Cargando...'}>
                <Switch>
                  <Route exact path="/" name="Home" render={props => <HomeContainer {...props} />} />
                  <Route exact path="/autologin" name="Home" render={props => <AutologinComponent {...props}/>} />
                  <Route exact path="/zeposia" name="Parámetros" render={props => <ParametrosEspecificosContainer {...props} />} />
                  <Route path={`/zeposia/parametros`} name="Parámetros" render={props => <ParametrosEspecificosContainer {...props} />} />
                  <Route path={`/zeposia/resultados`} name="Resultados" render={props => <ResultadosContainer {...props} />} />
                  <Route path={`/zeposia/costefarmacologico`} name="Coste Farmacológico" render={props => <CostesFarmacosContainer {...props} />} />
                  <Route path={`/zeposia/costefarmacologicoanual`} name="Coste Farmacológico Anual" render={props => <CosteFarmacologicoAnualContainer {...props} />} />
                  <Route path={`/zeposia/costecohorteanual`} name="Coste Farmacológico Anual" render={props => <CosteCohorteAnualContainer {...props} />} />
                  <Route path={`/zeposia/configuracion`} name="Configuración" render={props => <ConfiguracionContainer {...props} />} />
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </div>
          <Footer />
        </Orientation>
        <Orientation orientation='portrait' alwaysRender={false}>
          <ThemeProvider applyTo="body" theme={themeBMS} style={{ paddingTop: 10, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <div class="ms-Grid" dir="ltr" style={{ paddingLeft: 0, paddingRight: 0, textAlign: 'center' }}>
              <img src={logo} alt="logoBMS" style={{maxWidth: 343, maxHeight: 119, marginTop: 100 }} />
              <p style={{ fontSize: 25, padding: 10 }}>Coloque el dispositivo de forma horizontal para poder continuar con su uso.</p>
            </div>
          </ThemeProvider>
        </Orientation>
      </DeviceOrientation>
    </>
  );
}

export default App;
