export const themeBMS = {
    palette: {
        themePrimary: '#0032A0',
        themeLighterAlt: '#f1f4fb',
        themeLighter: '#cad6f0',
        themeLight: '#9fb4e3',
        themeSecondary: '#768692',
        themeTertiary: '#F9423A',
        themeDarkAlt: '#002e91',
        themeDark: '#00277a',
        themeDarker: '#001c5a',

        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#3f464f',
        white: '#ffffff',
    }
};