import React from 'react';

import imageBack from '../images/footer-calculadoras.png'
import { useScreenClass } from 'react-grid-system';

function Footer() {
    const screenClass = useScreenClass()
    return (
        <div id="footer" style={{
            position: 'fixed',
            padding: ['xs', 'sm', 'md'].includes(screenClass) ? '5px 10px 5px 10px' : '10px 10px 10px 10px',
            bottom: 0,
            width: '100%',
            /* Height of the footer*/
            textAlign: 'center',
            color: '#ffffff',
            backgroundColor:'#0032A0',
            fontSize: ['xs', 'sm', 'md'].includes(screenClass) ? '12px' : '14px'
        }}>
            <p style={{ margin: 0 }}>© {new Date().getFullYear()} Bristol-Myers Squibb Company</p>
        </div>
    )
}

export default Footer;